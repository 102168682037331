<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>档案库</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="goBack">{{ isOrganizational ? "组织管理" : type }}</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ action + type }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-form :form="editReceiveForm" class="receive-form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="基本信息" class="special__item">
              <span></span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="机构名称">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'corporationName',
                  {
                    rules: [{ required: true, message: '请输入机构名称' }]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.corporationName }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="合作状态">
              <a-radio-group
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'incorporateStatus',
                  {
                    rules: [{ required: true, message: '请选择合作状态' }]
                  }
                ]"
              >
                <a-radio value="正常">正常</a-radio>
                <a-radio value="已退出">已退出</a-radio>
                <a-radio value="暂停合作">暂停合作</a-radio>
              </a-radio-group>
              <span v-else>
                {{ info.incorporateStatus }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="机构类型">
              {{ type }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="统一社会信用代码">
              <a-input
                :disabled="action === '修改' || inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'corporationCode',
                  {
                    rules: [
                      { required: true, message: '请输入统一社会信用代码' }
                    ]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.corporationCode }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="联系人姓名">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'contactName',
                  {
                    rules: [{ required: true, message: '请输入联系人姓名' }]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.contactName }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="联系人电话">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'contactMobile',
                  {
                    rules: [
                      { required: true, message: '请输入联系人电话' },
                      { validator: isMobileCheck, message: '手机号码格式错误' }
                    ]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.contactMobile }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="联系地址区域">
              <a-cascader
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'areaCode',
                  {
                    rules: [{ required: true, message: '请选择联系地址区域' }]
                  }
                ]"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                :allow-clear="false"
                @change="onChangePer"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="联系地址详情">
              <a-input
                :disabled="inputDisabled"
                v-decorator="[
                  'registeredDetailAddress',
                  {
                    rules: [{ required: true, message: '请输入详细地址' }]
                  }
                ]"
                placeholder="请输入详细地址"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="经营地址区域">
              <a-cascader
                v-if="!isCheck"
                :disabled="action === '修改' || inputDisabled"
                v-decorator="[
                  'executorAreaCode',
                  {
                    rules: [{ required: true, message: '请选择经营地址区域' }]
                  }
                ]"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                :allow-clear="false"
                @change="onChangePeres"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="经营地址详情">
              <a-input
                v-if="!isCheck"
                :disabled="action === '修改' || inputDisabled"
                v-decorator="[
                  'executorDetailAddress',
                  {
                    rules: [{ required: true, message: '请输入详细地址' }]
                  }
                ]"
                placeholder="请输入详细地址"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="经销商编码">
              <a-input v-decorator="['dealerCode']" v-if="!isCheck" />
            </a-form-item>
          </a-col>
        </a-row>
        <!-- :events="action === '修改' ? null : events"-->
        <a-row v-if="type === '渠道终端'">
          <a-col :span="16">
            <a-form-item label="经营地址经纬度" required>
              <div class="amap-wrapper">
                <el-amap
                  class="amap-box"
                  vid="campMap"
                  :events="events"
                  :plugin="mapPlugin"
                >
                  <el-amap-marker
                    v-if="campLocation.length"
                    :position="campLocation"
                  ></el-amap-marker>
                </el-amap>
              </div>
            </a-form-item>
            <div class="tipInfo" v-if="action !== '修改'">
              地图上点击标记位置
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="认证信息" class="special__item">
              <span></span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="认证人身份">
              <a-radio-group
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'authType',
                  {
                    rules: [{ required: true, message: '请选择认证人身份' }]
                  }
                ]"
                @change="hasChangeAuth"
              >
                <a-radio value="法人">法人</a-radio>
                <a-radio value="经营人">经营人</a-radio>
              </a-radio-group>
              <span v-else>
                {{ info.authType }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="法人姓名">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'legalRepresentativeName',
                  {
                    rules: [{ required: true, message: '请输入法人姓名' }]
                  }
                ]"
                @change="legalName"
              ></a-input>
              <span v-else>
                {{ info.legalRepresentativeName }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="法人身份证">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'legalRepresentativeIdNo',
                  {
                    rules: [
                      { required: true, message: '请输入法人身份证' },
                      { validator: idCardCheck, message: '身份证号格式错误' }
                    ]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.legalRepresentativeIdNo }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="法人性别">
              <a-radio-group
                :disabled="true"
                v-if="!isCheck"
                v-decorator="[
                  'legalRepresentativeGender',
                  {
                    rules: [{ required: true, message: '请选择法人性别' }]
                  }
                ]"
              >
                <a-radio value="男">男</a-radio>
                <a-radio value="女">女</a-radio>
              </a-radio-group>
              <span v-else>
                {{ info.legalRepresentativeGender }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="法人人脸认证">
              <a-radio-group v-if="!isCheck" v-model="isLegal">
                <a-radio :value="true" disabled>是</a-radio>
                <a-radio :value="false" disabled>否</a-radio>
              </a-radio-group>
              <span v-else>
                已认证
              </span>
            </a-form-item>
          </a-col>
          <a-col v-if="!isLegalPerson" :span="12">
            <a-form-item label="经营人姓名">
              <a-input
                v-if="!isCheck"
                v-decorator="[
                  'executorName',
                  {
                    rules: [{ required: true, message: '请输入经营人姓名' }]
                  }
                ]"
                @change="executorName"
              ></a-input>
              <span v-else>
                {{ info.executorName }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="!isLegalPerson">
          <a-col :span="12">
            <a-form-item label="经营人身份证">
              <a-input
                v-if="!isCheck"
                v-decorator="[
                  'executorIdNo',
                  {
                    rules: [
                      { required: true, message: '请输入经营人身份证' },
                      { validator: idCardChecks, message: '身份证号格式错误' }
                    ]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.executorIdNo }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="经营人性别">
              <a-radio-group
                v-if="!isCheck"
                :disabled="true"
                v-decorator="[
                  'executorGender',
                  {
                    rules: [{ required: true, message: '请选择经营人性别' }]
                  }
                ]"
              >
                <a-radio value="男">男</a-radio>
                <a-radio value="女">女</a-radio>
              </a-radio-group>
              <span v-else>
                {{ info.executorGender }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="!isLegalPerson">
          <a-col :span="12">
            <a-form-item label="经营人人脸认证">
              <a-radio-group
                v-if="!isCheck"
                v-decorator="[
                  'executorAuthFlag',
                  {
                    rules: [{ required: true, message: '请选择是否认证' }]
                  }
                ]"
              >
                <a-radio :value="true" disabled>是</a-radio>
                <a-radio :value="false" disabled>否</a-radio>
              </a-radio-group>
              <span v-else>
                {{ info.executorAuthFlag ? "是" : "否" }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item
              v-if="!isCheck"
              label="营业执照"
              extra="图片大小限制在20MB以内"
            >
              <a-upload
                v-if="!isCheck"
                :disabled="inputDisabled"
                v-decorator="[
                  'businessLicenseUrl',
                  {
                    rules: [{ required: true, message: '请上传营业执照' }]
                  }
                ]"
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="imgUrl"
                :file-list="businessImages"
                :before-upload="
                  file =>
                    beforeUploadFile(
                      file,
                      'businessImages',
                      'cooperation_agreement',
                      'img'
                    )
                "
                @preview="handlePreviewImage"
                @change="handleChangeBusinessImage"
              >
                <div v-if="businessImages.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                style="z-index: 99"
                :visible="previewVisible"
                @ok="previewVisible = false"
                @cancel="previewVisible = false"
              >
                <img
                  :src="previewImage"
                  alt="logo"
                  style="width: 100%"
                  :footer="null"
                />
              </a-modal>
            </a-form-item>
            <a-form-item v-else label="营业执照">
              <img
                :src="imageBaseUrl + info.businessLicenseUrl"
                alt="营业执照"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="开票信息" class="special__item">
              <span></span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="单位名称">
              <a-input
                v-if="!isCheck"
                :disabled="true"
                v-decorator="['corporationName']"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="纳税人识别号">
              <a-input
                v-if="!isCheck"
                :disabled="true"
                v-model="corporationCode"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="注册地址区域">
              <a-cascader
                :disabled="inputDisabled"
                v-decorator="[
                  'registeredAreaCode',
                  {
                    rules: [{ required: true, message: '请输入注册地址详情' }]
                  }
                ]"
                :options="areaTreeData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                :allow-clear="false"
                @change="onChangePers"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="注册地址详情">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'invoiceDetailAddress',
                  {
                    rules: [{ required: true, message: '请输入注册地址详情' }]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.invoiceDetailAddress }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="开户行名称">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'invoiceBankName',
                  {
                    rules: [{ required: true, message: '请输入开户行名称' }]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.invoiceBankName }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开户行账号">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'invoiceBankAccount',
                  {
                    rules: [{ required: true, message: '请输入开户行账号' }]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.invoiceBankAccount }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="注册电话">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'invoiceTel',
                  {
                    rules: [{ required: true, message: '请输入注册电话' }]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.invoiceTel }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="收款银行卡名称" class="special__item">
              <span></span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="提现账户类型" required>
              <a-select
                :disabled="inputDisabled"
                v-decorator="['cardType', { initialValue: 'DCE' }]"
                @select="onSelectAccountType"
              >
                <a-select-option key="DCE">对公账户(推荐)</a-select-option>
                <a-select-option key="DC">对私法人账户</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="收款银行账号">
              <a-input
                :disabled="inputDisabled"
                v-if="!isCheck"
                v-decorator="[
                  'accountNumber',
                  {
                    rules: [{ required: true, message: '请输入收款银行账号' }]
                  }
                ]"
                @change="inputAccount"
              ></a-input>
              <span v-else>
                {{ info.accountNumber }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="收款银行名称">
              <a-select
                :disabled="
                  action === '新建' && $route.query.corporationId
                    ? inputDisabled
                    : disabledBank
                "
                v-decorator="[
                  'bankCode',
                  {
                    rules: [{ required: true, message: '请输入收款银行名称' }]
                  }
                ]"
                placeholder="请输入"
                :filter-option="false"
                @search="onSearchBank"
                show-search
                @popupScroll="onScrollBank"
                @select="onSelectBank"
              >
                <a-select-option v-for="item in bankList" :key="item.bankNo">
                  {{ item.bankName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="收款银行所在省市">
              <a-cascader
                :disabled="
                  action === '新建' && $route.query.corporationId
                    ? inputDisabled
                    : disabledBank
                "
                v-decorator="[
                  'provinceCityCode',
                  {
                    rules: [{ required: true, message: '请选择所在省市' }]
                  }
                ]"
                :options="provinceAndCityData"
                :field-names="areaTreeNames"
                placeholder="请选择区域"
                :allow-clear="false"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="收款行支行">
              <a-select
                :disabled="
                  action === '新建' && $route.query.corporationId
                    ? inputDisabled
                    : disabledBank
                "
                v-decorator="[
                  'branchCode',
                  {
                    rules: [{ required: true, message: '请输入开户行支行' }]
                  }
                ]"
                placeholder="请输入"
                :filter-option="false"
                @search="onSearchBranch"
                show-search
                @popupScroll="onScrollBranch"
                @select="onSelectBranch"
              >
                <a-select-option
                  v-for="item in branchList"
                  :key="item.branchId"
                >
                  {{ item.branchName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="isPersonalAccount">
          <a-col :span="12">
            <a-form-item label="银行卡正面照片" class="form__item">
              <a-upload
                :disabled="
                  action === '新建' && $route.query.corporationId
                    ? inputDisabled
                    : disabledBank
                "
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="cardUploadUrl"
                :file-list="cardFrontImage"
                :before-upload="
                  file =>
                    beforeUploadFile(
                      file,
                      'cardFrontImage',
                      'offline_payment_file',
                      'img'
                    )
                "
                @preview="handlePreviewImage"
                @change="handleChangeCardFrontImage"
              >
                <div v-if="cardFrontImage.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="银行卡反面照片" class="form__item">
              <a-upload
                :disabled="
                  action === '新建' && $route.query.corporationId
                    ? inputDisabled
                    : disabledBank
                "
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="cardUploadUrl"
                :file-list="cardBackImage"
                :before-upload="
                  file =>
                    beforeUploadFile(
                      file,
                      'cardBackImage',
                      'offline_payment_file',
                      'img'
                    )
                "
                @preview="handlePreviewImage"
                @change="handleChangeCardBackImage"
              >
                <div v-if="cardBackImage.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider />
        <a-row v-if="type === '制造原厂'">
          <a-col :span="12">
            <a-form-item label="产品目录">
              <a-input
                v-if="!isCheck"
                v-decorator="[
                  'productCatalog',
                  {
                    rules: [{ required: true, message: '请输入产品目录' }]
                  }
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col v-if="type === '服务中心' || type === '货栈'" :span="12">
            <a-form-item label="所在运营中心">
              <a-select
                :disabled="true"
                v-decorator="['parentId']"
                @change="chooseOperation"
              >
                <a-select-option
                  v-for="item in operationCenterData"
                  :key="item.corporationId"
                >
                  {{ item.corporationName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-if="type === '运营中心' || type === '服务中心'" :span="12">
            <a-form-item label="管辖区域">
              <a-tree-select
                v-if="!isCheck"
                :disabled="action === '修改'"
                v-decorator="[
                  'jurisdictionAreaCodeList',
                  {
                    rules: [{ required: true, message: '请选择管辖区域' }]
                  }
                ]"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="administerAreaData"
                multiple
                :replace-fields="areaTreeFields"
                tree-node-filter-prop="title"
                :allow-clear="true"
                tree-checkable
                :show-checked-strategy="SHOW_PARENT"
              ></a-tree-select>
              <span v-else>
                {{ administerAreaName }}
              </span>
            </a-form-item>
          </a-col>
          <a-col v-if="type === '货栈'" :span="12">
            <a-form-item label="资金服务费（年化）">
              <a-input
                v-if="!isCheck"
                v-decorator="[
                  'serviceCharge',
                  {
                    rules: [{ required: true, message: '请输入资金服务费' }]
                  }
                ]"
              ></a-input>
              <span v-else>
                {{ info.serviceCharge }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="type !== '制造原厂'">
          <a-col :span="12">
            <a-form-item label="加盟协议">
              <div>
                <a-upload
                  v-decorator="[
                    'cooperationAgreement',
                    {
                      rules: [{ required: true, message: '请上传加盟协议' }]
                    }
                  ]"
                  name="file"
                  accept=".doc,.docx"
                  :multiple="true"
                  :action="`${baseURL}/upload-word/cooperation_agreement`"
                  :headers="headers"
                  :file-list="fileList"
                  :before-upload="
                    file =>
                      beforeUploadFile(
                        file,
                        'fileList',
                        'cooperation_agreement',
                        'file'
                      )
                  "
                  @change="value => onChangeReport(value)"
                >
                  <a v-if="fileList.length === 0" class="btn--upload">
                    点击上传
                  </a>
                </a-upload>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="合同起止时间">
              <a-range-picker
                v-if="!isCheck"
                v-decorator="[
                  'time',
                  {
                    rules: [{ required: true, message: '请选择合同起止时间' }]
                  }
                ]"
                :disabled-date="disabledDate"
                allow-clear
                @change="onChangeTime"
              />
              <span v-else>
                暂无信息
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="type === '服务中心'">
          <a-row
            v-for="(item, index) in reportWarehouseList"
            :key="index"
            class="choose__row"
          >
            <a-col :span="12">
              <a-form-item label="绑定货栈">
                <a-select
                  @change="ite => hasChooseWarehouse(ite, index)"
                  v-model="item.corporationId"
                  class="short__choose"
                >
                  <a-select-option
                    v-for="items in warehouseData"
                    :key="items.corporationId"
                  >
                    {{ items.corporationName }}
                  </a-select-option>
                </a-select>
                <img
                  v-if="reportWarehouseList && reportWarehouseList.length > 1"
                  src="../../assets/removeValue.png"
                  alt=""
                  class="removeIcon"
                  @click="removeWourse(index)"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="合同起止时间">
                <a-range-picker
                  v-model="item.time"
                  allow-clear
                  :disabled-date="disabledDate"
                  @change="
                    (date, dateString) => onChangeTimes(date, dateString, index)
                  "
                  class="short__choose"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="三方协议">
                <a-upload
                  v-model="item.fileList"
                  name="file"
                  accept=".doc,.docx"
                  :multiple="true"
                  :action="`${baseURL}/upload-word/cooperation_agreement`"
                  :headers="headers"
                  :file-list="item.fileList"
                  :before-upload="file => beforeUpload(file, index)"
                  @change="value => onChangeReports(value, index)"
                >
                  <a v-if="item.fileList.length === 0" class="btn--upload">
                    点击上传
                  </a>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <div
          class="item__addIcon"
          @click="addRiding"
          v-if="type === '服务中心'"
        >
          <a-icon type="plus-square" theme="twoTone" class="addIcon" />
          增加绑定
        </div>
        <a-row v-if="type === '运营中心'">
          <a-col :span="12">
            <a-form-item label="任务完成率要求">
              <a-input-number
                v-if="!isCheck"
                v-decorator="[
                  'taskCompletionRate',
                  {
                    rules: [{ required: true, message: '请输入任务完成率' }]
                  }
                ]"
                :min="0"
                :max="100"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
              />
              <span v-else>{{ info.taskCompletionRate }}%</span>
            </a-form-item>
            <a-form-item label="销售完成率要求">
              <a-input-number
                v-if="!isCheck"
                v-decorator="[
                  'salesCompletionRate',
                  {
                    rules: [{ required: true, message: '请输入销售完成率' }]
                  }
                ]"
                :min="0"
                :max="100"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')"
              />
              <span v-else>{{ info.salesCompletionRate }}%</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="type === '运营中心'">
          <a-form-item label="销售量要求">
            <a-col :span="12">
              <a-table
                v-if="!hasChangeData"
                :data-source="tblData"
                :columns="showSalesColumns"
                :pagination="false"
                :loading="loading"
                row-key="id"
              ></a-table>
              <a-button
                type="primary"
                class="btn-reset"
                style="margin-top: 12px"
                @click="showModal = true"
              >
                修改配置
              </a-button>
              <a-modal
                title="销售量要求"
                :visible="showModal"
                width="60%"
                @ok="reportModal"
                @cancel="unReportModal"
              >
                <a-button
                  type="primary"
                  class="btn-reset"
                  @click="onAddModalTable"
                >
                  增加
                </a-button>
                <a-table
                  class="table"
                  :data-source="modalData"
                  :columns="editSalesColumns"
                  :pagination="false"
                  row-key="id"
                >
                  <template slot="salesMonth" slot-scope="text, record">
                    <div v-for="item in modalData" :key="item.id">
                      <div v-if="item.id === record.id">
                        <a-select
                          v-model="item.salesMonth"
                          style="width: 100px;"
                          :options="monthOptions"
                          allow-clear
                          @change="hasChooseMonth(record)"
                        ></a-select>
                      </div>
                    </div>
                  </template>
                  <template slot="salesVolume" slot-scope="text, record">
                    <div v-for="item in modalData" :key="item.id">
                      <div v-if="item.id === record.id">
                        <a-input v-model="item.salesVolume"></a-input>
                      </div>
                    </div>
                  </template>
                  <a-space
                    v-if="modalData.length > 1"
                    slot="action"
                    slot-scope="text, record"
                  >
                    <a-popconfirm
                      title="是否确认删除?"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="onDeleteModalItem(record.id)"
                    >
                      <a>删除</a>
                    </a-popconfirm>
                  </a-space>
                </a-table>
              </a-modal>
            </a-col>
          </a-form-item>
        </a-row>
        <a-row v-if="type === '制造原厂'">
          <a-col>
            <a-form-item label="产品图片">
              <a-upload
                v-if="!isCheck"
                v-decorator="[
                  'productImages',
                  {
                    rules: [{ required: true, message: '请上传产品图片' }]
                  }
                ]"
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="imgUrl"
                :file-list="productImages"
                :before-upload="
                  file =>
                    beforeUploadFile(
                      file,
                      'productImages',
                      'product_picture',
                      'img'
                    )
                "
                @preview="handlePreviewImage"
                @change="handleChangeProductImage"
              >
                <div>
                  <a-icon type="plus" />
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="type === '制造原厂'">
          <a-col>
            <a-form-item label="3C证书">
              <a-upload
                v-if="!isCheck"
                v-decorator="[
                  'threeCImages',
                  {
                    rules: [{ required: true, message: '请上传3C证书' }]
                  }
                ]"
                name="file"
                list-type="picture-card"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                :show-upload-list="true"
                :action="imgUrl"
                :file-list="threeCImages"
                :before-upload="
                  file =>
                    beforeUploadFile(
                      file,
                      'threeCImages',
                      'product_picture',
                      'img'
                    )
                "
                @preview="handlePreviewImage"
                @change="handleChangeThreeCImage"
              >
                <div>
                  <a-icon type="plus" />
                </div>
              </a-upload>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="type === '渠道终端'">
          <a-col :span="12">
            <a-form-item label="开通业务">
              <a-checkbox-group v-decorator="['flag']">
                <a-checkbox value="sharedBusinessFlag">共享</a-checkbox>
                <a-checkbox value="leasingBusinessFlag">租赁</a-checkbox>
                <a-checkbox value="carLeaseBusinessFlag">分期购车</a-checkbox>
                <a-checkbox value="sellBusinessFlag">
                  批量买卖业务
                </a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item
              label="合作等级"
              v-if="
                editReceiveForm.getFieldsValue().flag
                  ? editReceiveForm.getFieldsValue().flag.length == 0
                    ? true
                    : false
                  : true
              "
            >
              <a-select
                v-decorator="['configId']"
                :options="platList"
                placeholder="请选择合作等级"
                allow-clear
              />
            </a-form-item>
            <a-form-item label="合作等级" v-else>
              <a-select
                v-decorator="[
                  'configId',
                  {
                    rules: [{ required: true, message: '请选择合作等级' }]
                  }
                ]"
                :options="platList"
                placeholder="请选择合作等级"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="!isCheck" class="footer">
          <a-button type="primary" @click="onSavePart" v-if="inputDisabled">
            保存
          </a-button>
          <a-button
            type="primary"
            @click="onSave"
            v-else
            :loading="saveLoading"
          >
            保存
          </a-button>
          <a-button @click="goBack">返回</a-button>
        </div>
        <div v-else class="footer">
          <a-button type="primary" @click="onModify">修改</a-button>
          <a-button @click="goBack">返回</a-button>
        </div>
      </a-form>
    </section>
  </a-layout-content>
</template>

<script>
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { buildHeaders, toChinesNum } from "@/components/utils";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import { fetchProvinceAndCityArea, loadArea } from "@/services/DealerService";
import { imgUrl, upload } from "@/services/UploadService";
import { TreeSelect } from "ant-design-vue";
import {
  addArchives,
  fetchAllOperationCenter,
  fetchAllServiceCenter,
  fetchAllWarehouse,
  fetchArchivesArea,
  fetchArchivesDetail,
  fetchBankCardInfoByAccount,
  fetchExecutor,
  fetchUserAuthenticated,
  judgeAccountNumberIsExist,
  modifyArchives,
  newAddArchives,
  searchBank,
  searchBranch
} from "@/services/Archives";
import moment from "moment";
import { platformList } from "@/services/ValueBag";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const areaTreeFields = {
  children: "subAreas",
  title: "areaName",
  key: "areaCode",
  value: "areaCode"
};
const showSalesColumns = [
  {
    title: "月份",
    dataIndex: "salesMonth",
    width: "50%"
  },
  {
    title: "销售数量",
    dataIndex: "salesVolume",

    width: "50%"
  }
];
const editSalesColumns = [
  {
    title: "月份",
    dataIndex: "salesMonth",
    scopedSlots: { customRender: "salesMonth" },
    width: "33%"
  },
  {
    title: "销售数量",
    dataIndex: "salesVolume",
    scopedSlots: { customRender: "salesVolume" },
    width: "33%"
  },
  {
    title: "操作",
    key: "action",

    scopedSlots: { customRender: "action" },
    width: "34%"
  }
];
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
  data() {
    const vm = this;
    return {
      SHOW_PARENT,
      isFirst: true,
      areaTreeFields,
      toChinesNum,
      showModal: false,
      showSalesColumns,
      editSalesColumns,
      defaultData: [
        {
          id: 1,
          salesMonth: "",
          salesVolume: ""
        }
      ],
      tblData: [],
      monthId: 2,
      modalData: [
        {
          id: 1,
          salesMonth: "",
          salesVolume: ""
        }
      ],
      fileList: [],
      isLegal: "",
      administerAreaCode: [],
      administerAreaName: [],
      reportFileList: {},
      imageBaseUrl,
      isModifyAllow: "",
      invoiceProvinceCode: "",
      invoiceCityCode: "",
      invoiceCountyCode: "",
      tsFormat,
      checkCooperationUrl: "",
      checkCooperationName: "",
      baseURL,
      hasChangeFile: false,
      tableData: [],
      loading: false,
      procureOrderSn: this.$route.params.procureOrderSn,
      type: this.$route.params.type,
      action: this.$route.params.action,
      info: {},
      isCheck: false,
      headers: buildHeaders(),
      dateMonth: "",
      monthSkuList: [],
      isLegalPerson: true,
      editReceiveForm: this.$form.createForm(this),
      areaCode: [], // 选择的省市区code
      executorAreaCode: [], // 选择的省市区code
      areaTreeData: [], // 行政区数据
      administerAreaData: [], // 管辖行政区数据
      corporationId: this.$route.params.id,
      distributorId: this.$route.query.distributorId,
      startTime: "",
      reportWarehouseList: [
        {
          corporationId: undefined,
          time: [],
          fileList: [],
          cooperationAgreementFile: {}
        }
      ],
      endTime: "",
      reportType: "",
      idCardIsCorrect: "",
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      }, // 行政区树格式
      imgUrl,
      cardUploadUrl: `${baseURL}/upload/offline_payment_file`,
      businessImages: [],
      productImages: [],
      threeCImages: [],
      cardFrontImage: [],
      cardBackImage: [],
      isOrganizational: false,
      testCode: "1201",
      corporationCode: "",
      previewVisible: false,
      previewImage: "",
      registeredAreaName: "",
      serviceCenterData: [],
      warehouseData: [],
      operationCenterData: [],
      logisticsList: [],
      hasChangeData: false,
      monthOptions: [
        { value: "1", label: "一月" },
        { value: "2", label: "二月" },
        { value: "3", label: "三月" },
        { value: "4", label: "四月" },
        { value: "5", label: "五月" },
        { value: "6", label: "六月" },
        { value: "7", label: "七月" },
        { value: "8", label: "八月" },
        { value: "9", label: "九月" },
        { value: "10", label: "十月" },
        { value: "11", label: "十一月" },
        { value: "12", label: "十二月" }
      ],
      platList: [],
      inputDisabled: false, // 新建机构类型进入的新建, 部分信息是带入,不可编辑的
      bankList: [],
      branchList: [],
      searchBankValue: "",
      searchBranchValue: "",
      bankPageNum: 1,
      branchPageNum: 1,
      bankTotal: 0,
      branchTotal: 0,
      provinceAndCityData: [],
      bankName: "",
      branchName: "",
      isPersonalAccount: false,
      saveLoading: false,
      accountNumber: "",
      disabledBank: false,
      //地图插件
      mapPlugin: ["ToolBar"],
      //地图事件处理
      events: {
        //点击地图标记基地地址
        click(mapsEvent) {
          let clickedLocation = new Array();
          let { lng, lat } = mapsEvent.lnglat;
          clickedLocation.push(lng);
          clickedLocation.push(lat);
          vm.campLocation = clickedLocation;
        }
      },
      campLocation: []
    };
  },
  mounted() {
    this.loadProvinceAndCityData();
    this.editReceiveForm.setFieldsValue({
      authType: "法人"
    });
    if (this.type === "渠道终端") {
      this.reportType = "endConsumerMarket";
      platformList({ pageSize: 999, pageNum: 1 }).then(resp => {
        resp.data.data.records.forEach(item => {
          this.platList.push({
            label: item.levelName,
            value: item.configId
          });
        });
      });
    } else if (this.type === "货栈") {
      this.reportType = "warehouse";
    } else if (this.type === "运营中心") {
      this.reportType = "operationCenter";
    } else if (this.type === "服务中心") {
      this.reportType = "serviceCenter";
    } else if (this.type === "制造原厂") {
      this.reportType = "factory";
    } else if (this.type === "运营总部") {
      this.reportType = "operationHeadquarters";
    }
    this.isCheck = this.action === "查看";
    if (this.action === "组织管理修改") {
      this.action = "修改";
      this.isOrganizational = true;
    }
    this.onLoadArea();
    this.onLoadServiceCenter();
    this.onLoadOperationCenter();
    this.onLoadAllWarehouse();
    this.$nextTick(() => {
      if (this.corporationId) {
        this.onDetail();
      }
    });
    // 从 新建机构 跳转过来的 新建
    if (this.action === "新建" && this.$route.query.corporationId) {
      this.inputDisabled = true;
      this.$nextTick(() => {
        // 获取对应的详情,回显到页面上
        this.getPartDetail();
      });
    }
    this.bankPageNum = 1;
    this.loadBankData();
  },
  methods: {
    // 获取所有的货栈
    onLoadAllWarehouse() {
      fetchAllWarehouse().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.warehouseData = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    reportModal() {
      let hasLeisure = false;
      this.modalData.forEach(item => {
        if (item.salesMonth === "" || item.salesVolume === "") {
          hasLeisure = true;
        }
      });
      if (hasLeisure) {
        this.$message.warning("有未填完要求");
        return;
      }
      this.tblData = JSON.parse(JSON.stringify(this.modalData));
      this.showModal = false;
    },
    unReportModal() {
      if (this.tblData && this.tblData.length > 0) {
        this.modalData = JSON.parse(JSON.stringify(this.tblData));
      } else {
        this.modalData = this.defaultData;
      }
      this.showModal = false;
    },
    // 获取档案详情
    onDetail() {
      fetchArchivesDetail(this.reportType, this.distributorId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.info = resp.data.data;
          if (this.type == "渠道终端") {
            let flag = [];
            if (data.sharedBusinessFlag) {
              flag.push("sharedBusinessFlag");
            }
            if (data.leasingBusinessFlag) {
              flag.push("leasingBusinessFlag");
            }
            if (data.carLeaseBusinessFlag) {
              flag.push("carLeaseBusinessFlag");
            }
            if (data.sellBusinessFlag) {
              flag.push("sellBusinessFlag");
            }
            this.editReceiveForm.setFieldsValue({
              configId: data.configId,
              flag: flag
            });

            // 如果有经纬度就回显到地图上
            this.campLocation = [];
            data.longitude && this.campLocation.push(data.longitude);
            data.latitude && this.campLocation.push(data.latitude);
          }
          if (this.info.parentId) {
            this.chooseOperation(this.info.parentId);
          }
          this.tblData = this.info.centerSalesVolumeVOList;
          if (this.tblData && this.tblData.length > 0) {
            this.tblData.forEach((item, index) => {
              item.salesMonth = item.salesMonth.toString();
              item.id = index;
            });
            this.monthId = this.tblData.length + 1;
          }
          this.modalData = JSON.parse(JSON.stringify(this.tblData));
          if (this.info.cooperationAgreementFile) {
            this.checkCooperationUrl = this.info.cooperationAgreementFile.fileUrl;
            this.checkCooperationName = this.info.cooperationAgreementFile.fileName;
          }
          if (this.info.registeredAreaName) {
            this.info.registeredAreaName = this.info.registeredAreaName.trim();
          }

          if (
            this.info.jurisdictionAreaCodeMap &&
            this.info.jurisdictionAreaCodeMap.length > 0
          ) {
            this.info.jurisdictionAreaCodeMap.forEach(item => {
              this.administerAreaName.push(Object.values(item));
              this.administerAreaCode.push(Object.keys(item));
            });
            this.administerAreaCode = [].concat.apply(
              [],
              this.administerAreaCode
            );
            this.administerAreaName = [].concat
              .apply([], this.administerAreaName)
              .toString();
          }
          this.editReceiveForm.setFieldsValue({ ...data });
          this.editReceiveForm.setFieldsValue({
            jurisdictionAreaCodeList: this.administerAreaCode
          });
          this.corporationCode = this.info.corporationCode;
          this.invoiceProvinceCode = this.info.invoiceProvinceCode;
          this.invoiceCityCode = this.info.invoiceCityCode;
          this.invoiceCountyCode = this.info.invoiceCountyCode;
          this.accountNumber =
            this.info && this.info.collectionCard
              ? this.info.collectionCard.accountNumber
              : "";
          // this.loadAccountIsExist();
          if (
            this.info &&
            this.info.collectionCard &&
            this.info.collectionCard.branchCode &&
            this.info.collectionCard.branchName
          ) {
            this.branchName = this.info.collectionCard.branchName;
            this.bankName = this.info.collectionCard.bankName;
            this.bankList = [
              {
                bankNo: this.info.collectionCard.bankCode,
                bankName: this.info.collectionCard.bankName
              }
            ];
            this.branchList = [
              {
                branchId: this.info.collectionCard.branchCode,
                branchName: this.info.collectionCard.branchName
              }
            ];
            this.editReceiveForm.setFieldsValue({
              branchCode: this.info.collectionCard.branchCode
            });
            this.editReceiveForm.setFieldsValue({
              bankCode: this.info.collectionCard.bankCode
            });
          }
          this.isPersonalAccount =
            this.info && this.info.collectionCard
              ? this.info.collectionCard.cardType === "DC"
                ? true
                : false
              : false;
          this.editReceiveForm.setFieldsValue({
            registeredAreaCode: [
              this.info.invoiceProvinceCode,
              this.info.invoiceCityCode,
              this.info.invoiceCountyCode
            ],
            cardType:
              this.info && this.info.collectionCard
                ? this.info.collectionCard.cardType
                : "",
            accountNumber:
              this.info && this.info.collectionCard
                ? this.info.collectionCard.accountNumber
                : "",
            provinceCityCode:
              this.info &&
              this.info.collectionCard &&
              this.info.collectionCard.provinceCode &&
              this.info.collectionCard.cityCode
                ? [
                    this.info.collectionCard.provinceCode,
                    this.info.collectionCard.cityCode
                  ]
                : []
          });
          if (data.collectionCard && data.collectionCard.frontFile) {
            const params = {
              uid: 0,
              fileUrl: data.collectionCard.frontFile,
              name: "",
              status: "done",
              url: imageBaseUrl + data.collectionCard.frontFile
            };
            this.cardFrontImage.push(params);
          }
          if (data.collectionCard && data.collectionCard.backFile) {
            const params = {
              uid: 0,
              fileUrl: data.collectionCard.backFile,
              name: "",
              status: "done",
              url: imageBaseUrl + data.collectionCard.backFile
            };
            this.cardBackImage.push(params);
          }
          const warehouseList = [];
          if (this.info.warehouseList && this.info.warehouseList.length > 0) {
            this.info.warehouseList.forEach(item => {
              warehouseList.push(item.corporationId);
            });
          }
          this.editReceiveForm.setFieldsValue({
            warehouseIdList: warehouseList
          });
          let contractVO;
          this.isModifyAllow = this.info.contractVO
            ? this.info.contractVO.isModifyAllow
            : "";
          if (
            this.info.tripartiteAgreementVOList &&
            this.info.tripartiteAgreementVOList.length > 0
          ) {
            contractVO = this.info.tripartiteAgreementVOList[0].contractVO;
            if (contractVO && contractVO.incorporateStartDate) {
              this.editReceiveForm.setFieldsValue({
                time: [
                  tsFormat(contractVO.incorporateStartDate),
                  tsFormat(contractVO.incorporateEndDate)
                ]
              });
              this.startTime = dtFormat(contractVO.incorporateStartDate);
              this.endTime = dtFormat(contractVO.incorporateEndDate);
            }
          } else {
            if (
              this.info.contractVO &&
              this.info.contractVO.incorporateStartDate
            ) {
              contractVO = this.info.contractVO;
              this.editReceiveForm.setFieldsValue({
                time: [
                  tsFormat(this.info.contractVO.incorporateStartDate),
                  tsFormat(this.info.contractVO.incorporateEndDate)
                ]
              });
              this.startTime = dtFormat(
                this.info.contractVO.incorporateStartDate
              );
              this.endTime = dtFormat(this.info.contractVO.incorporateEndDate);
            }
          }

          this.areaCode = [
            data.registeredProvinceCode,
            data.registeredCityCode,
            data.registeredCountyCode
          ];
          this.editReceiveForm.setFieldsValue({
            areaCode: [
              data.registeredProvinceCode,
              data.registeredCityCode,
              data.registeredCountyCode
            ]
          });
          this.editReceiveForm.setFieldsValue({
            executorAreaCode: [
              data.executorProvinceCode,
              data.executorCityCode,
              data.executorCountyCode
            ]
          });
          this.executorAreaCode = [
            data.executorProvinceCode,
            data.executorCityCode,
            data.executorCountyCode
          ];
          if (data.businessLicenseUrl) {
            const params = {
              uid: 0,
              fileUrl: data.businessLicenseUrl,
              name: data.businessLicenseUrl,
              status: "done",
              url: imageBaseUrl + data.businessLicenseUrl
            };
            this.businessImages.push(params);
          }
          if (contractVO && contractVO.cooperationAgreementFile) {
            const params = {
              uid: 0,
              fileUrl: contractVO.cooperationAgreementFile.fileUrl,
              name: contractVO.cooperationAgreementFile.fileName,
              status: "done",
              url: imageBaseUrl + contractVO.cooperationAgreementFile.fileUrl
            };
            this.editReceiveForm.setFieldsValue({
              cooperationAgreement: params
            });
            this.fileList.push(params);
            const reportParams = {
              fileUrl: contractVO.cooperationAgreementFile.fileUrl,
              fileName: contractVO.cooperationAgreementFile.fileName
            };
            this.reportFileList = reportParams;
          }
          if (data.factoryProductsPictureFiles) {
            const imgList = [];
            data.factoryProductsPictureFiles.forEach((item, index) => {
              const params = {
                uid: index,
                fileUrl: item.fileUrl,
                name: item.fileName,
                status: "done",
                url: imageBaseUrl + item.fileUrl
              };
              imgList.push(params);
            });
            this.productImages = imgList;
            this.editReceiveForm.setFieldsValue({
              productImages: imgList
            });
          }
          if (data.certificate3cFiles) {
            const imgList = [];
            data.certificate3cFiles.forEach((item, index) => {
              const params = {
                uid: index,
                fileUrl: item.fileUrl,
                name: item.fileName,
                status: "done",
                url: imageBaseUrl + item.fileUrl
              };
              imgList.push(params);
            });
            this.threeCImages = imgList;
            this.editReceiveForm.setFieldsValue({
              threeCImages: imgList
            });
          }
          this.isAuthen(
            this.editReceiveForm.getFieldsValue().legalRepresentativeIdNo,
            this.editReceiveForm.getFieldsValue().legalRepresentativeName,
            "LEGAL_REPRESENTATIVE"
          );
          if (
            this.info.warehouseBindContractVOList &&
            this.info.warehouseBindContractVOList.length > 0
          ) {
            this.info.warehouseBindContractVOList.forEach((item, index) => {
              item.fileList = [];
              let params = {};
              if (item.cooperationAgreementFile) {
                params = {
                  uid: index,
                  fileUrl: item.cooperationAgreementFile.fileUrl,
                  name: item.cooperationAgreementFile.fileName,
                  status: "done",
                  url: imageBaseUrl + item.fileUrl
                };
              }
              item.fileList.push(params);
              item.incorporateEndDate = item.incorporateEndDate
                ? dtFormat(item.incorporateEndDate)
                : "";
              item.incorporateStartDate = item.incorporateStartDate
                ? dtFormat(item.incorporateStartDate)
                : "";
              item.time = item.incorporateStartDate
                ? [
                    tsFormat(item.incorporateStartDate),
                    tsFormat(item.incorporateEndDate)
                  ]
                : [];
            });
            this.info.warehouseBindContractVOList.forEach(item => {
              if (item.time[0] === "Invalid date") {
                item.time = [];
              }
              if (item.fileList.length > 0) {
                if (!item.fileList[0].fileUrl) {
                  item.fileList = [];
                }
              }
            });
            this.reportWarehouseList = this.info.warehouseBindContractVOList;
          }
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    onDeleteModalItem(id) {
      this.modalData.forEach((item, index) => {
        if (item.id === id) {
          this.modalData.splice(index, 1);
        }
      });
    },
    hasChooseWarehouse(item, index) {
      let hasSame = false;
      this.reportWarehouseList.forEach((ite, ind) => {
        if (ite.corporationId === item && index !== ind) {
          hasSame = true;
          this.reportWarehouseList[index].corporationId = "";
        }
      });
      if (hasSame) {
        this.$message.warning("已选择过该货栈，不可重复选择");
      }
    },
    // 切换认证信息
    hasChangeAuth(value) {
      if (value.target.value === "法人") {
        this.isLegalPerson = true;
      } else {
        this.isLegalPerson = false;
      }
    },
    // 新建月份
    onAddModalTable() {
      const params = {
        id: this.monthId,
        salesVolume: "",
        salesMonth: ""
      };
      this.modalData.push(params);
      this.monthId += this.modalData;
    },
    // 获取服务中心列表
    onLoadServiceCenter() {
      fetchAllServiceCenter().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.serviceCenterData = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    isUnifiedCode(rule, item) {
      this.corporationCode = item;
      let isCorrect = false;
      const reg = /^([1-9ABCDEFGHJKLMNPQRTUWXY]{2}\d{6}[0-9ABCDEFGHJKLMNPQRTUWXY]{10})$/;
      if (!reg.test(item)) {
        isCorrect = false;
      } else {
        isCorrect = true;
      }
      return isCorrect;
    },
    isMobileCheck(rule, item) {
      let isCorrect = false;
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(item)) {
        isCorrect = false;
      } else {
        isCorrect = true;
      }
      return isCorrect;
    },
    // 法人身份证号格式检查
    idCardCheck(rule, value) {
      if (value && value.length === 18) {
        this.idCardIsCorrect = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          value
        );
        // 满足18位校验，然后需要校验最后一位校验码
        if (this.idCardIsCorrect) {
          // 得到的最后一位校验码
          const checkDigit = this.checkDigitCheck(value);
          // 取到当前身份证号的最后一位
          const number = value.substring(17, 18);
          if (checkDigit || checkDigit === 0) {
            if (checkDigit === "X") {
              this.idCardIsCorrect =
                String(number) === "X" || String(number) === "x";
            } else {
              this.idCardIsCorrect = parseInt(number) === parseInt(checkDigit);
            }
          } else {
            this.idCardIsCorrect = false;
          }
        }
      } else {
        this.idCardIsCorrect = false;
      }
      if (this.editReceiveForm.getFieldsValue().legalRepresentativeName) {
        this.isAuthen(
          value,
          this.editReceiveForm.getFieldsValue().legalRepresentativeName,
          "LEGAL_REPRESENTATIVE"
        );
      }
      if (this.idCardIsCorrect) {
        let lastChar = value.charAt(value.length - 2);
        let lastNumber = parseInt(lastChar);
        let gender = lastNumber % 2 === 0 ? "女" : "男";
        this.editReceiveForm.setFieldsValue({
          legalRepresentativeGender: gender
        });
      } else {
        this.editReceiveForm.setFieldsValue({
          legalRepresentativeGender: ""
        });
      }
      return this.idCardIsCorrect;
    },
    // 不可选日期
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf("day");
    },
    // 查看法人或经营人是否已认证
    isAuthen(id, name, type) {
      fetchUserAuthenticated(id, name, type).then(resp => {
        if (resp.data.code === "SUCCESS") {
          if (type === "LEGAL_REPRESENTATIVE") {
            this.isLegal = resp.data.data;
          } else {
            this.editReceiveForm.setFieldsValue({
              executorAuthFlag: resp.data.data
            });
          }
        }
      });
    },
    legalName(value) {
      if (this.editReceiveForm.getFieldsValue().legalRepresentativeIdNo) {
        this.isAuthen(
          this.editReceiveForm.getFieldsValue().legalRepresentativeIdNo,
          value.target.value,
          "LEGAL_REPRESENTATIVE"
        );
      }
    },
    executorName(value) {
      if (this.editReceiveForm.getFieldsValue().executorIdNo) {
        this.isAuthen(
          this.editReceiveForm.getFieldsValue().executorIdNo,
          value.target.value,
          "EXECUTOR"
        );
      }
    },
    addRiding() {
      this.reportWarehouseList.push({
        corporationId: undefined,
        time: [],
        fileList: [],
        cooperationAgreementFile: {}
      });
    },
    removeWourse(index) {
      this.reportWarehouseList.splice(index, 1);
    },
    // 经营人身份证号格式检查
    idCardChecks(rule, value) {
      if (value && value.length === 18) {
        this.idCardIsCorrect = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
          value
        );
        // 满足18位校验，然后需要校验最后一位校验码
        if (this.idCardIsCorrect) {
          // 得到的最后一位校验码
          const checkDigit = this.checkDigitCheck(value);
          // 取到当前身份证号的最后一位
          const number = value.substring(17, 18);
          if (checkDigit || checkDigit === 0) {
            if (checkDigit === "X") {
              this.idCardIsCorrect =
                String(number) === "X" || String(number) === "x";
            } else {
              this.idCardIsCorrect = parseInt(number) === parseInt(checkDigit);
            }
          } else {
            this.idCardIsCorrect = false;
          }
        }
      } else {
        this.idCardIsCorrect = false;
      }
      if (this.idCardIsCorrect) {
        let lastChar = value.charAt(value.length - 2);
        let lastNumber = parseInt(lastChar);
        let gender = lastNumber % 2 === 0 ? "女" : "男";
        this.editReceiveForm.setFieldsValue({
          executorGender: gender
        });
      } else {
        this.editReceiveForm.setFieldsValue({
          executorGender: ""
        });
      }
      if (this.editReceiveForm.getFieldsValue().executorName) {
        this.isAuthen(
          this.idCardIsCorrect,
          this.editReceiveForm.getFieldsValue().executorName,
          "EXECUTOR"
        );
      }
      return this.idCardIsCorrect;
    },
    // 检查身份证号的最后一位校验码
    checkDigitCheck: function(idCard) {
      const sum =
        parseInt(idCard.substring(0, 1)) * 7 +
        parseInt(idCard.substring(1, 2)) * 9 +
        parseInt(idCard.substring(2, 3)) * 10 +
        parseInt(idCard.substring(3, 4)) * 5 +
        parseInt(idCard.substring(4, 5)) * 8 +
        parseInt(idCard.substring(5, 6)) * 4 +
        parseInt(idCard.substring(6, 7)) * 2 +
        parseInt(idCard.substring(7, 8)) +
        parseInt(idCard.substring(8, 9)) * 6 +
        parseInt(idCard.substring(9, 10)) * 3 +
        parseInt(idCard.substring(10, 11)) * 7 +
        parseInt(idCard.substring(11, 12)) * 9 +
        parseInt(idCard.substring(12, 13)) * 10 +
        parseInt(idCard.substring(13, 14)) * 5 +
        parseInt(idCard.substring(14, 15)) * 8 +
        parseInt(idCard.substring(15, 16)) * 4 +
        parseInt(idCard.substring(16, 17)) * 2;
      const remainder = sum % 11;
      switch (remainder) {
        case 0:
          return 1;
        case 1:
          return 0;
        case 2:
          return "X";
        case 3:
          return 9;
        case 4:
          return 8;
        case 5:
          return 7;
        case 6:
          return 6;
        case 7:
          return 5;
        case 8:
          return 4;
        case 9:
          return 3;
        case 10:
          return 2;
        default:
          return null;
      }
    },
    // 获取运营中心列表
    onLoadOperationCenter() {
      fetchAllOperationCenter().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.operationCenterData = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    chooseOperation(value) {
      this.editReceiveForm.setFieldsValue({
        jurisdictionAreaCodeList: []
      });
      this.administerAreaData = [];
      fetchArchivesArea(value).then(resp => {
        if (resp.data.code === "SUCCESS") {
          resp.data.data.forEach(ite => {
            this.areaTreeData.forEach(item => {
              if (ite.corporationAreaCode.length === 2) {
                if (item.areaCode === ite.corporationAreaCode) {
                  this.administerAreaData.push(item);
                }
              }
              if (ite.corporationAreaCode.length === 4) {
                item.subAreas.forEach(itemOne => {
                  if (itemOne.areaCode === ite.corporationAreaCode) {
                    this.administerAreaData.push(itemOne);
                  }
                });
              }
              if (ite.corporationAreaCode.length === 6) {
                item.subAreas.forEach(itemTwo => {
                  itemTwo.subAreas.forEach(itemTwo => {
                    if (itemTwo.areaCode === ite.corporationAreaCode) {
                      this.administerAreaData.push(itemTwo);
                    }
                  });
                });
              }
            });
          });
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 修改档案
    onModify() {
      this.$router.push({
        name: "ModifyArchives",
        params: {
          type: this.type,
          action: "修改",
          id: this.corporationId
        },
        query: {
          distributorId: this.$route.query.distributorId
        }
      });
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    onChangeTimes(date, dateString, index) {
      if (dateString && dateString.length > 0) {
        this.reportWarehouseList.forEach((item, ind) => {
          if (index === ind) {
            item.incorporateStartDate = dateString[0];
            item.incorporateEndDate = dateString[1];
          }
        });
        this.reportWarehouseList = [...this.reportWarehouseList];
      }
    },
    //上传三方协议
    onChangeReports({ fileList }, index) {
      this.reportWarehouseList.forEach((item, ind) => {
        if (index === ind) {
          item.fileList = fileList;
        }
      });
      var nFileArr = new Array();
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        let keep = true;
        if (file.status !== "error") {
          if (file.response) {
            if (file.response.code === "FAILED") {
              keep = false;
              this.$message.error(file.response.errorMsg);
              return;
            }
          }
        }
        if (keep) {
          nFileArr.push(file);
        }
      }
      if (nFileArr.length > 0) {
        if (nFileArr[0].response && nFileArr[0].response.data) {
          const params = {
            uid: 0,
            fileUrl: nFileArr[0].response.data,
            name: nFileArr[0].name,
            status: "done",
            url: imageBaseUrl + nFileArr[0].response.data
          };
          const reportParams = {
            fileUrl: nFileArr[0].response.data,
            fileName: nFileArr[0].name
          };
          this.reportWarehouseList.forEach((item, ind) => {
            if (index === ind) {
              item.fileList.push(params);
              item.cooperationAgreementFile = reportParams;
              item.fileList.splice(0, 1);
            }
          });
        }
        this.reportWarehouseList = [...this.reportWarehouseList];
      }
      this.reportWarehouseList = [...this.reportWarehouseList];
    },
    // 获取文件
    onChangeReport({ fileList }) {
      this.reportFileList = {};
      this.fileList = fileList;
      var nFileArr = new Array();
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        let keep = true;
        if (file.status !== "error") {
          if (file.response) {
            if (file.response.code === "FAILED") {
              keep = false;
              this.$message.error(file.response.errorMsg);
              return;
            }
          }
        }
        if (keep) {
          nFileArr.push(file);
        }
      }
      if (nFileArr.length > 0) {
        if (nFileArr[0].response && nFileArr[0].response.data) {
          const params = {
            uid: 0,
            fileUrl: nFileArr[0].response.data,
            name: nFileArr[0].name,
            status: "done",
            url: imageBaseUrl + nFileArr[0].response.data
          };
          const reportParams = {
            fileUrl: nFileArr[0].response.data,
            fileName: nFileArr[0].name
          };
          this.fileList.push(params);
          this.reportFileList = reportParams;
          this.fileList.splice(0, 1);
        }
        this.hasChangeFile = true;
      } else {
        this.fileList = nFileArr;
        this.hasChangeFile = true;
      }
    },
    // 拼接所选行政区信息
    onChangePer(value, selectOptions) {
      this.areaCode = value;
      this.registeredAreaName = "";
      if (selectOptions && selectOptions.length > 0) {
        selectOptions.forEach(item => {
          this.registeredAreaName = `${this.registeredAreaName} ${item.areaName}`;
        });
        const arrValue = this.registeredAreaName.split(" ");
        this.areaValues = arrValue;
        if (arrValue[2] === arrValue[1]) {
          arrValue.splice(1, 1);
          this.registeredAreaName = arrValue.join(" ");
        }
      }
    },
    onChangePeres(value) {
      this.executorAreaCode = value;
      if (this.type !== "服务中心") {
        return;
      }
      fetchExecutor(value[0], value[1], value[2]).then(resp => {
        if (resp.data.code === "SUCCESS") {
          if (resp.data.data) {
            this.$nextTick(() => {
              this.editReceiveForm.setFieldsValue({
                parentId: resp.data.data.corporationId
              });
              this.chooseOperation(resp.data.data.corporationId);
            });
          } else {
            this.editReceiveForm.setFieldsValue({
              parentId: ""
            });
            this.onLoadArea();
          }
        }
      });
    },
    // 拼接所选行政区信息
    onChangePers(value) {
      this.registeredAreaCode = value;
      this.invoiceProvinceCode = value[0];
      this.invoiceCityCode = value[1];
      this.invoiceCountyCode = value[2];
    },
    // 加载行政区数据
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
          if (this.type === "运营中心" || this.type === "服务中心") {
            this.administerAreaData = this.areaTreeData;
          }
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 判断能否可选
    hasChooseMonth(record) {
      let same = false;
      this.modalData.forEach(item => {
        if (item.id !== record.id && item.salesMonth === record.salesMonth) {
          same = true;
        }
        if (item.id === record.id && same) {
          this.$message.warning("已经选择过该月份");
          item.salesMonth = "";
        }
      });
    },
    // 返回上一层
    goBack() {
      this.$router.go(-1);
    },
    onSave() {
      if (!this.isFirst) {
        return;
      }
      this.editReceiveForm.validateFields((err, values) => {
        if (!err) {
          if (this.type !== "制造原厂") {
            if (this.fileList.length === 0) {
              this.$message.error("请上传加盟协议");
              return;
            }
            if (this.type === "运营中心") {
              if (this.tblData.length === 0) {
                this.$message.warning("请上传销售量要求");
                return;
              }
            }
            if (this.type === "服务中心") {
              let reportWarehouseList = [];
              this.reportWarehouseList.forEach(item => {
                let params = {
                  corporationId: item.corporationId,
                  cooperationAgreementFile: item.cooperationAgreementFile,
                  incorporateStartDate: item.incorporateStartDate,
                  incorporateEndDate: item.incorporateEndDate
                };
                reportWarehouseList.push(params);
              });
              reportWarehouseList.forEach(item => {
                if (item.incorporateEndDate === "Invalid date") {
                  item.incorporateEndDate = null;
                  item.incorporateStartDate = null;
                }
              });
              values.warehouseBindContractFormList = reportWarehouseList;
            }
            if (this.type === "渠道终端") {
              if (!this.campLocation || !this.campLocation.length) {
                this.$message.error("请选择经营地址经纬度");
                return;
              }
              values.longitude = this.campLocation[0];
              values.latitude = this.campLocation[1];

              values.sharedBusinessFlag =
                values.flag.indexOf("sharedBusinessFlag") != -1;
              values.leasingBusinessFlag =
                values.flag.indexOf("leasingBusinessFlag") != -1;
              values.carLeaseBusinessFlag =
                values.flag.indexOf("carLeaseBusinessFlag") != -1;
              values.sellBusinessFlag =
                values.flag.indexOf("sellBusinessFlag") != -1;
            }
            values.centerSalesVolumeFormList = this.tblData;
            const params = {};
            if (this.startTime) {
              params.incorporateStartDate = this.startTime;
              params.incorporateEndDate = this.endTime;
            }
            if (!this.hasChangeFile && this.action === "修改") {
              if (this.info.contractVO) {
                params.contractNo = this.info.contractVO.contractNo;
              }
            } else if (this.hasChangeFile) {
              params.contractNo = "";
            }
            params.cooperationAgreementFile = this.reportFileList;
            values.contractForm = params;
          }
          values.distributorType = this.reportType;
          if (this.areaCode.length > 0) {
            values.registeredProvinceCode = this.areaCode[0];
            values.registeredCityCode = this.areaCode[1];
            values.registeredCountyCode = this.areaCode[2];
          }
          if (this.executorAreaCode.length > 0) {
            values.executorProvinceCode = this.executorAreaCode[0];
            values.executorCityCode = this.executorAreaCode[1];
            values.executorCountyCode = this.executorAreaCode[2];
          }
          if (this.businessImages.length === 0) {
            this.$message.info("请上传营业执照");
            return;
          }
          let imgUrl = "";
          const proDuctImgUrl = [];
          const threeCImgUrl = [];
          if (this.businessImages.length > 0) {
            this.businessImages.forEach(item => {
              if (item.fileUrl) {
                imgUrl = item.fileUrl;
              } else if (item.response) {
                imgUrl = item.response.data;
              }
            });
          }
          if (this.productImages.length > 0) {
            this.productImages.forEach(item => {
              if (item.fileUrl) {
                proDuctImgUrl.push({
                  fileName: item.fileUrl,
                  fileUrl: item.fileUrl
                });
              } else if (item.response) {
                proDuctImgUrl.push({
                  fileName: item.response.data,
                  fileUrl: item.response.data
                });
              }
            });
          }
          if (this.threeCImages.length > 0) {
            this.threeCImages.forEach(item => {
              if (item.fileUrl) {
                threeCImgUrl.push({
                  fileName: item.fileUrl,
                  fileUrl: item.fileUrl
                });
              } else if (item.response) {
                threeCImgUrl.push({
                  fileName: item.response.data,
                  fileUrl: item.response.data
                });
              }
            });
          }
          values.businessLicenseUrl = imgUrl;
          values.factoryProductsPictureFiles = proDuctImgUrl;
          values.certificate3cFiles = threeCImgUrl;
          values.registeredAreaName = this.registeredAreaName;
          values.invoiceProvinceCode = this.invoiceProvinceCode;
          values.invoiceCityCode = this.invoiceCityCode;
          values.invoiceCountyCode = this.invoiceCountyCode;
          let frontUrl = "";
          let backUrl = "";
          if (this.cardFrontImage.length === 0 && values.cardType === "DC") {
            this.$message.info("请上传银行卡正面照片");
            return;
          }
          if (this.cardBackImage.length === 0 && values.cardType === "DC") {
            this.$message.info("请上传银行卡背面照片");
            return;
          }
          if (this.cardFrontImage.length > 0) {
            this.cardFrontImage.forEach(item => {
              if (item.fileUrl) {
                frontUrl = item.fileUrl;
              } else if (item.response) {
                frontUrl = item.response.data;
              }
            });
          }
          if (this.cardBackImage.length > 0) {
            this.cardBackImage.forEach(item => {
              if (item.fileUrl) {
                backUrl = item.fileUrl;
              } else if (item.response) {
                backUrl = item.response.data;
              }
            });
          }
          const collectionCardForm = {
            cardType: values.cardType,
            accountNumber: values.accountNumber,
            bankName: this.bankName,
            bankCode: values.bankCode,
            provinceCode: values.provinceCityCode[0],
            cityCode: values.provinceCityCode[1],
            branchName: this.branchName,
            branchCode: values.branchCode,
            frontFile: frontUrl,
            backFile: backUrl,
            invoiceBankName: values.invoiceBankName,
            invoiceBankAccount: values.invoiceBankAccount
          };
          this.saveLoading = true;
          values.collectionCardForm = collectionCardForm;
          this.isFirst = false;
          if (this.action === "新建") {
            addArchives(values)
              .then(resp => {
                this.saveLoading = false;
                if (resp.data.code === "SUCCESS") {
                  this.$message.success("创建成功");
                  this.$router.go(-1);
                } else {
                  this.isFirst = true;
                }
              })
              .catch(() => {
                this.saveLoading = false;
                this.isFirst = true;
              });
          } else {
            values.corporationId = this.corporationId;
            values.distributorId = this.$route.query.distributorId;
            modifyArchives(values)
              .then(resp => {
                this.saveLoading = false;
                if (resp.data.code === "SUCCESS") {
                  this.$message.success("修改成功");
                  this.$router.go(-1);
                } else {
                  this.isFirst = true;
                }
              })
              .catch(() => {
                this.saveLoading = false;
                this.isFirst = true;
              });
          }
        } else {
          this.isFirst = true;
        }
      });
    },
    beforeUploadFile(file, fileListName, type, format) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("文件名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("文件大小超过20MB");
        } else {
          this.uploadFile(type, file, fileListName, format);
        }
      }
      return false;
    },
    async uploadFile(type, file, fileListName, format) {
      let fileFormat = "";
      if (format === "file") {
        fileFormat = "pdf";
      } else {
        fileFormat = "other";
      }
      const { data = {} } = await upload(type, file, fileFormat);
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        let list = [];
        list.push({
          uid: list.length,
          name: file.name,
          fileName: file.name,
          url: imageBaseUrl + data.data,
          fileUrl: data.data,
          response: data
        });
        this[fileListName] = [...list];
        if (fileListName === "fileList") {
          this.reportFileList = this.fileList[0];
        }
      }
    },
    // 图片转Base64格式方便查看
    async handlePreviewImage(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传营业执照
    handleChangeBusinessImage({ fileList }) {
      this.businessImages = fileList.filter(item => item.url);
    },
    // 上传银行卡正面
    handleChangeCardFrontImage({ fileList }) {
      this.cardFrontImage = fileList.filter(item => item.url);
    },
    // 上传银行卡背面
    handleChangeCardBackImage({ fileList }) {
      this.cardBackImage = fileList.filter(item => item.url);
    },
    // 上传产品图
    handleChangeProductImage({ fileList }) {
      this.productImages = fileList.filter(item => item.url);
    },
    // 上传3C证书
    handleChangeThreeCImage({ fileList }) {
      this.threeCImages = fileList.filter(item => item.url);
    },
    // 增加机构类型跳转过来的新建  获取部分数据
    async getPartDetail() {
      const { data } = await fetchArchivesDetail(
        this.$route.query.currentType,
        this.$route.query.corporationId
      );
      // 回填信息
      const info = data.data;

      // 渠道终端 回显经营地址 经纬度
      if (info.longitude && info.latitude) {
        this.campLocation = [info.longitude, info.latitude];
      }

      this.editReceiveForm.setFieldsValue({
        invoiceBankName: info.invoiceBankName,
        invoiceBankAccount: info.invoiceBankAccount,
        corporationName: info.corporationName, // 机构名称
        incorporateStatus: info.incorporateStatus, // 合作状态
        corporationCode: info.corporationCode, // 社会信用代码
        contactName: info.contactName, // 联系人姓名
        contactMobile: info.contactMobile, // 联系人电话
        // 联系地址
        areaCode: [
          info.registeredProvinceCode,
          info.registeredCityCode,
          info.registeredCountyCode
        ],
        registeredDetailAddress: info.registeredDetailAddress,
        // 经营地址
        executorAreaCode: [
          info.executorProvinceCode,
          info.executorCityCode,
          info.executorCountyCode
        ],
        executorDetailAddress: info.executorDetailAddress,
        authType: info.authType, // 认证人身份
        legalRepresentativeName: info.legalRepresentativeName, // 法人姓名
        legalRepresentativeIdNo: info.legalRepresentativeIdNo, // 法人身份证号
        legalRepresentativeGender: info.legalRepresentativeGender, // 法人性别
        businessLicenseUrl: info.businessLicenseUrl, // 营业执照
        // 注册地址
        registeredAreaCode: [
          info.invoiceProvinceCode,
          info.invoiceCityCode,
          info.invoiceCountyCode
        ],
        invoiceDetailAddress: info.invoiceDetailAddress,
        invoiceTel: info.invoiceTel, // 注册电话
        cardType:
          this.info && info.collectionCard ? info.collectionCard.cardType : "",
        accountNumber:
          info && info.collectionCard ? info.collectionCard.accountNumber : "",
        provinceCityCode:
          info &&
          info.collectionCard &&
          info.collectionCard.provinceCode &&
          info.collectionCard.cityCode
            ? [info.collectionCard.provinceCode, info.collectionCard.cityCode]
            : []
      });
      if (
        info &&
        info.collectionCard &&
        info.collectionCard.branchCode &&
        info.collectionCard.branchName
      ) {
        this.branchName = info.collectionCard.branchName;
        this.branchList = [
          {
            branchId: info.collectionCard.branchCode,
            branchName: info.collectionCard.branchName
          }
        ];
        this.editReceiveForm.setFieldsValue({
          branchCode: info.collectionCard.branchCode,
          branchName: info.collectionCard.branchName,
          bankCode: info.collectionCard.bankCode
        });
      }
      this.accountNumber =
        info && info.collectionCard ? info.collectionCard.accountNumber : "";
      // this.loadAccountIsExist();
      this.isPersonalAccount =
        info && info.collectionCard
          ? info.collectionCard.cardType === "DC"
            ? true
            : false
          : false;
      this.corporationCode = info.corporationCode; // 纳税人识别号
      this.isLegal = info.legalRepresentativeAuthFlag; // 法人是否认证
      this.businessImages.push({
        uid: 0,
        fileUrl: info.businessLicenseUrl,
        name: info.businessLicenseUrl,
        status: "done",
        url: imageBaseUrl + info.businessLicenseUrl
      });
      if (info.collectionCard && info.collectionCard.frontFile) {
        const params = {
          uid: 0,
          fileUrl: info.collectionCard.frontFile,
          name: "frontFile",
          status: "done",
          url: imageBaseUrl + info.collectionCard.frontFile
        };
        this.cardFrontImage.push(params);
      }
      if (info.collectionCard && info.collectionCard.backFile) {
        const params = {
          uid: 0,
          fileUrl: info.collectionCard.backFile,
          name: "backFile",
          status: "done",
          url: imageBaseUrl + info.collectionCard.backFile
        };
        this.cardBackImage.push(params);
      }
    },
    // 增加机构类型跳转过来的新建 提交
    onSavePart() {
      this.editReceiveForm.validateFields(async (err, values) => {
        if (!err) {
          const obj = {};
          // 运营中心
          if (this.type === "运营中心") {
            if (this.tblData.length === 0) {
              this.$message.warning("请上传销售量要求");
              return;
            }
          }
          obj.warehouseBindContractFormList = [];
          //  服务中心
          if (this.type === "服务中心") {
            let reportWarehouseList = [];
            this.reportWarehouseList.forEach(item => {
              let params = {
                corporationId: item.corporationId,
                cooperationAgreementFile: item.cooperationAgreementFile,
                incorporateStartDate: item.incorporateStartDate,
                incorporateEndDate: item.incorporateEndDate
              };
              reportWarehouseList.push(params);
            });
            reportWarehouseList.forEach(item => {
              if (item.incorporateEndDate === "Invalid date") {
                item.incorporateEndDate = null;
                item.incorporateStartDate = null;
              }
            });
            obj.warehouseBindContractFormList = reportWarehouseList; // 货栈列表
          }
          obj.centerSalesVolumeFormList = this.tblData || []; // 销售要求
          const params = {};
          if (this.startTime) {
            params.incorporateStartDate = this.startTime;
            params.incorporateEndDate = this.endTime;
          }
          if (!this.hasChangeFile && this.action === "修改") {
            if (this.info.contractVO) {
              params.contractNo = this.info.contractVO.contractNo;
            }
          } else if (this.hasChangeFile) {
            params.contractNo = "";
          }
          params.cooperationAgreementFile = this.reportFileList || [];
          obj.contractForm = params || {}; // 加盟协议
          obj.distributorType = this.reportType || ""; // 机构类型
          obj.taskCompletionRate = values.taskCompletionRate || ""; // 任务完成率(运营中心)
          obj.jurisdictionAreaCodeList = values.jurisdictionAreaCodeList || []; // 管辖区域
          obj.salesCompletionRate = values.salesCompletionRate || ""; // 销售完成率(运营中心)
          const { data } = await newAddArchives(
            this.$route.query.corporationId,
            obj
          );
          if (data.code === "SUCCESS") {
            this.$message.success("创建成功");
            this.$router.go(-1);
          } else {
            this.$message.error(data.errorMsg);
          }
        }
      });
    },
    // 加载银行数据
    loadBankData() {
      const params = {
        keyword: this.searchBankValue,
        pageNum: this.bankPageNum,
        pageSize: 20
      };
      if (this.bankPageNum === 1) {
        this.bankList = [];
      }
      searchBank(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.bankTotal = data.total;
          if (data.records && data.records.length > 0) {
            data.records.forEach(item => {
              this.bankList.push(item);
            });
          }
        }
      });
    },
    // 搜索银行
    onSearchBank(value) {
      if (value) {
        this.searchBankValue = value;
        this.bankPageNum = 1;
        this.loadBankData();
      }
    },
    // 选择银行
    onSelectBank(value) {
      let item = this.bankList.find(ele => ele.bankNo === value);
      this.bankName = item.bankName;
      this.editReceiveForm.setFieldsValue({ branchCode: "" });
      this.branchName = "";
      this.branchPageNum = 1;
      this.branchList = [];
      this.loadBranchData();
    },
    // 选择支行
    onSelectBranch(value) {
      let item = this.branchList.find(ele => ele.branchId === value);
      this.branchName = item.branchName;
    },
    // 加载支行数据
    loadBranchData() {
      const params = {
        bankNo: this.editReceiveForm.getFieldsValue().bankCode
          ? this.editReceiveForm.getFieldsValue().bankCode.length === 8
            ? this.editReceiveForm.getFieldsValue().bankCode
            : this.editReceiveForm
                .getFieldsValue()
                .bankCode.toString()
                .padStart(8, "0")
          : "",
        keyword: this.searchBranchValue,
        pageNum: this.branchPageNum,
        pageSize: 20
      };
      if (this.branchPageNum === 1) {
        this.branchList = [];
      }
      searchBranch(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.branchTotal = data.total;
          if (data.records && data.records.length > 0) {
            data.records.forEach(item => {
              this.branchList.push(item);
            });
          }
        }
      });
    },
    // 搜索支行
    onSearchBranch(value) {
      if (value) {
        this.searchBranchValue = value;
        this.branchPageNum = 1;
        this.loadBranchData();
      }
    },
    onScrollBank({ target }) {
      if (this.bankList.length >= this.bankTotal) {
        return;
      }
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.bankPageNum++;
        this.loadBankData();
      }
    },
    onScrollBranch({ target }) {
      if (this.branchList.length >= this.branchTotal) {
        return;
      }
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.branchPageNum++;
        this.loadBranchData();
      }
    },
    // 加载省市地区数据
    loadProvinceAndCityData() {
      fetchProvinceAndCityArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.provinceAndCityData = resp.data.data || [];
        }
      });
    },
    // 选择账户类型
    onSelectAccountType(value) {
      if (value === "DCE") {
        this.isPersonalAccount = false;
      } else {
        this.isPersonalAccount = true;
      }
      // this.loadAccountIsExist();
    },
    // 输入银行卡号
    inputAccount(e) {
      if (e.target.value) {
        this.accountNumber = e.target.value;
        // this.loadAccountIsExist();
      } else {
        this.bankPageNum = 1;
        this.loadBankData();
      }
    },
    // 查询账户信息是否存在
    loadAccountIsExist() {
      if (!this.accountNumber) {
        return;
      }
      judgeAccountNumberIsExist(this.accountNumber).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (!data) {
            this.disabledBank = false;
            this.bankList = [];
            this.editReceiveForm.setFieldsValue({
              bankCode: "",
              branchCode: "",
              provinceCityCode: []
            });
            this.bankName = "";
            this.branchName = "";
            this.branchList = [];
            this.cardFrontImage = [];
            this.cardBackImage = [];
            this.loadAccountInfo();
          } else {
            if (this.action === "修改") {
              this.disabledBank = false;
            } else {
              this.disabledBank = true;
            }
            this.bankList = [
              {
                bankNo: data.bankCode,
                bankName: data.bankName
              }
            ];
            this.editReceiveForm.setFieldsValue({
              bankCode: data.bankCode,
              branchCode: data.branchCode,
              provinceCityCode:
                data.provinceCode && data.cityCode
                  ? [data.provinceCode, data.cityCode]
                  : []
            });
            this.bankName = data.bankName;
            this.branchName = data.branchName;
            this.branchList = [
              {
                branchId: data.branchCode,
                branchName: data.branchName
              }
            ];
            this.cardFrontImage = [];
            this.cardBackImage = [];
            if (data && data.frontFile) {
              const params = {
                uid: 0,
                fileUrl: data.frontFile,
                name: "frontFile",
                status: "done",
                url: imageBaseUrl + data.frontFile
              };
              this.cardFrontImage.push(params);
            }
            if (data && data.backFile) {
              const params = {
                uid: 0,
                fileUrl: data.backFile,
                name: "backFile",
                status: "done",
                url: imageBaseUrl + data.backFile
              };
              this.cardBackImage.push(params);
            }
          }
        }
      });
    },
    // 查询账户信息
    loadAccountInfo() {
      if (!this.accountNumber) {
        return;
      }
      fetchBankCardInfoByAccount(this.accountNumber).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data) {
            this.bankList = [
              {
                bankNo: data.bankCode,
                bankName: data.bankName
              }
            ];
            this.editReceiveForm.setFieldsValue({
              bankCode: data.bankCode
            });
            this.bankName = data.bankName;
            this.branchPageNum = 1;
            this.loadBranchData();
          }
        }
      });
    },
    beforeUpload(file, index) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      if (fileName.length > 100) {
        this.$message.error("文件名称的长度需在100个字以内");
      } else {
        if (size > 20 * 1024 * 1024) {
          this.$message.error("文件大小超过20MB");
        } else {
          this.uploadAgreement(file, index);
        }
      }
      return false;
    },
    async uploadAgreement(file, index) {
      const { data = {} } = await upload("cooperation_agreement", file);
      if (data.code === "FAILED") {
        this.$message.error(data.errorMsg);
      } else {
        let list = [];
        list.push({
          uid: list.length,
          name: file.name,
          fileName: file.name,
          url: imageBaseUrl + data.data,
          fileUrl: data.data,
          response: data
        });
        this.reportWarehouseList[index].fileList = [...list];
        this.reportWarehouseList = [...this.reportWarehouseList];
      }
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}

/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
  overflow: auto;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
  min-width: 137px !important;
}

.special__item /deep/ .ant-form-item-label > label {
  color: #101010;
  font-size: 14px;
  font-weight: bold;
}

.special__item /deep/ .ant-form-item-label > label::after {
  content: "";
  width: 4px;
}

.footer {
  display: flex;
  align-items: center;
}

.footer button {
  margin-right: 8px;
}

.btn-reset {
  margin-bottom: 12px;
}

/deep/ .ant-col-12 {
  width: 45%;
}

/deep/ .ant-row .ant-col-12:first-child {
  margin-right: 10%;
}

.item__addIcon {
  color: #3769fc;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.addIcon {
  font-size: 24px;
  margin-right: 12px;
}

.short__choose {
  width: 60%;
}

.removeIcon {
  width: 30px;
  margin-left: 30px;
  cursor: pointer;
}

.choose__row {
  border: 1px solid rgb(205, 205, 205);
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 12px;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
}

.form__item /deep/ .ant-form-item-label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.amap-wrapper {
  width: 450px;
  height: 350px;
}
.tipInfo {
  position: absolute;
  top: 32px;
  left: 0;
  font-size: 12px;
  color: #fb9090;
}
</style>
