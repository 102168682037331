<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>档案库</a-breadcrumb-item>
        <a-breadcrumb-item>
          <a @click="goBack">{{ type }}</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>{{ action + type }}</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <section class="info-container">
      <a-form :form="editReceiveForm" class="receive-form">
        <a-row>
          <a-col :span="12">
            <a-form-item label="基本信息" class="special__item">
              <span></span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="机构名称">
              <span>
                {{ info.corporationName }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="合作状态">
              <span>
                {{ info.incorporateStatus }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="渠道类型">
              {{ type }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="统一社会信用代码">
              <span>
                {{ info.corporationCode }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="联系人姓名">
              <span>
                {{ info.contactName }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="联系人电话">
              <span>
                {{ info.contactMobile }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="联系地址">
              <span>
                {{
                  info.registeredAreaName
                    ? replace(info.registeredAreaName) +
                      info.registeredDetailAddress
                    : ""
                }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="经营地址">
              <span>
                {{ info.executorFullAreaName }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="经销商编码">
              <span>{{ info.dealerCode || "-" }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="type === '渠道终端'">
          <a-col :span="16">
            <a-form-item label="经营地址经纬度">
              <div class="amap-wrapper">
                <el-amap class="amap-box" vid="campMap" :plugin="mapPlugin">
                  <el-amap-marker
                    v-if="campLocation.length"
                    :position="campLocation"
                  ></el-amap-marker>
                </el-amap>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="认证信息" class="special__item">
              <span></span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="认证人身份">
              <span>
                {{ info.authType }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="法人姓名">
              <span>
                {{ info.legalRepresentativeName }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="法人身份证">
              <span>
                {{ info.legalRepresentativeIdNo }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="法人性别">
              <span>
                {{ info.legalRepresentativeGender }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="法人人脸认证">
              <span>
                {{ info.legalRepresentativeAuthFlag ? "是" : "否" }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="经营人姓名">
              <span>
                {{ info.executorName || "--" }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="经营人身份证">
              <span>
                {{ info.executorIdNo || "--" }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="经营人性别">
              <span>
                {{ info.executorGender || "--" }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="经营人人脸认证">
              <span>
                {{ info.executorAuthFlag ? "是" : "否" }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="营业执照" v-viewer>
              <img
                :data-source="imageBaseUrl + info.businessLicenseUrl"
                style="width: 100%"
                :src="imageBaseUrl + info.businessLicenseUrl"
                :alt="info.businessLicenseName"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="开票信息" class="special__item">
              <span></span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="单位名称">
              <span>
                {{ info.corporationName }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="纳税人识别号">
              <span>
                {{ info.corporationCode }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="注册地址区域">
              <span>
                {{ info.invoiceAreaName }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="注册地址详情">
              <span>
                {{ info.invoiceDetailAddress }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="开户行名称">
              <span>
                {{ info.invoiceBankName || "--" }}
              </span>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开户行账号">
              <span>
                {{ info.invoiceBankAccount || "--" }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="注册电话">
              <span>
                {{ info.invoiceTel }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="收款银行卡名称" class="special__item">
              <span></span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="提现账户类型">
              {{
                info.collectionCard && info.collectionCard.cardType
                  ? info.collectionCard.cardType === "DC"
                    ? "对私法人账户"
                    : "对公账户"
                  : "-"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开户银行账号">
              {{
                info.collectionCard && info.collectionCard.accountNumber
                  ? info.collectionCard.accountNumber
                  : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="开户银行名称">
              {{
                info.collectionCard && info.collectionCard.bankName
                  ? info.collectionCard.bankName
                  : "-"
              }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="开户银行所在省市">
              {{
                info.collectionCard
                  ? `${info.collectionCard.provinceName}${info.collectionCard.cityName}`
                  : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="开户行支行">
              {{
                info.collectionCard && info.collectionCard.branchName
                  ? info.collectionCard.branchName
                  : "-"
              }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row
          v-if="info.collectionCard && info.collectionCard.cardType === 'DC'"
        >
          <a-col :span="12">
            <a-form-item label="银行卡正面照片">
              <div
                v-viewer
                v-if="info.collectionCard && info.collectionCard.frontFile"
              >
                <img
                  :data-source="imageBaseUrl + info.collectionCard.frontFile"
                  class="preview__image"
                  :src="imageBaseUrl + info.collectionCard.frontFile"
                  alt="银行卡正面照片"
                />
              </div>
              <div v-else>暂无照片</div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="银行卡反面照片">
              <div
                v-viewer
                v-if="info.collectionCard && info.collectionCard.backFile"
              >
                <img
                  :data-source="imageBaseUrl + info.collectionCard.backFile"
                  class="preview__image"
                  :src="imageBaseUrl + info.collectionCard.backFile"
                  alt="银行卡反面照片"
                />
              </div>
              <div v-else>暂无照片</div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider />
        <a-row>
          <a-col v-if="type === '渠道终端'" :span="12">
            <a-form-item label="所在服务中心">
              <span>{{ info.serviceCentreName }}</span>
            </a-form-item>
          </a-col>
          <a-col
            v-if="type === '服务中心' || type === '货栈' || type === '渠道终端'"
            :span="12"
          >
            <a-form-item label="所在运营中心">
              <span>{{ info.operatingCenterName }}</span>
            </a-form-item>
          </a-col>
          <a-col v-if="type === '运营中心' || type === '服务中心'" :span="12">
            <a-form-item label="管辖区域">
              <span>
                {{
                  administerAreaName && administerAreaName.length > 0
                    ? administerAreaName
                    : "暂无信息"
                }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col v-if="checkCooperationUrl" :span="12">
            <a-form-item
              :label="
                contractVO.contractSignStatus === '待签署'
                  ? '加盟协议(签署中)'
                  : '加盟协议'
              "
            >
              <div class="link__url">
                <a :href="imageBaseUrl + checkCooperationUrl" target="_blank">
                  {{ checkCooperationName }}
                </a>
              </div>
            </a-form-item>
          </a-col>
          <a-col v-if="contractVO" :span="12">
            <a-form-item label="合同起止时间">
              <span>
                {{
                  contractVO && contractVO.incorporateStartDate
                    ? dtFormat(contractVO.incorporateStartDate)
                    : "暂无信息"
                }}
                ~
                {{
                  contractVO && contractVO.incorporateEndDate
                    ? dtFormat(contractVO.incorporateEndDate)
                    : "暂无信息"
                }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
        <div v-if="type === '服务中心'">
          <div
            v-for="(item, index) in info.warehouseBindContractVOList"
            :key="index"
          >
            <a-row>
              <a-col :span="12">
                <a-form-item label="绑定货栈">
                  {{ item.corporationName ? item.corporationName : "--" }}
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="三方协议">
                  <div class="link__url">
                    <a
                      v-if="item.cooperationAgreementFile"
                      :href="
                        imageBaseUrl + item.cooperationAgreementFile.fileUrl
                      "
                    >
                      {{ item.cooperationAgreementFile.fileName }}
                    </a>
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="合同起止时间">
                  <span>
                    {{
                      item && item.incorporateStartDate
                        ? dtFormat(item.incorporateStartDate)
                        : "暂无信息"
                    }}
                    ~
                    {{
                      item && item.incorporateEndDate
                        ? dtFormat(item.incorporateEndDate)
                        : "暂无信息"
                    }}
                  </span>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </div>
        <a-row v-if="type === '运营中心'">
          <a-col :span="12">
            <a-form-item label="任务完成率要求">
              <span>{{ info.taskCompletionRate }}%</span>
            </a-form-item>
            <a-form-item label="销售完成率要求">
              <span>{{ info.salesCompletionRate }}%</span>
            </a-form-item>
          </a-col>
          <a-col :span="12"></a-col>
        </a-row>
        <a-row v-if="type === '运营中心'">
          <a-form-item label="销售量要求">
            <a-col :span="12">
              <a-table
                :data-source="tblData"
                :columns="showSalesColumns"
                :pagination="false"
                :loading="loading"
                row-key="id"
              ></a-table>
            </a-col>
          </a-form-item>
        </a-row>
        <a-row v-if="type === '制造原厂'">
          <a-form-item label="产品图片">
            <div
              v-for="(item, index) in info.factoryProductsPictureFiles"
              :key="index"
              v-viewer
            >
              <img
                :data-source="imageBaseUrl + item.fileUrl"
                style="width: 30%;margin-right: 3%"
                :src="imageBaseUrl + item.fileUrl"
                :alt="item.fileName"
              />
            </div>
          </a-form-item>
        </a-row>
        <a-row v-if="type === '制造原厂'">
          <a-form-item label="3C证书">
            <div
              v-viewer
              v-for="(item, index) in info.certificate3cFiles"
              :key="index"
            >
              <img
                :data-source="imageBaseUrl + item.fileUrl"
                style="width: 30%;margin-right: 3%"
                :src="imageBaseUrl + item.fileUrl"
                :alt="item.fileName"
              />
            </div>
          </a-form-item>
        </a-row>
        <a-row v-if="type === '渠道终端'">
          <a-col :span="12">
            <a-form-item label="开通业务">
              {{ info.flag && info.flag.length > 0 ? info.flag.join() : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="合作等级">
              {{ info.levelName ? info.levelName : "-" }}
            </a-form-item>
          </a-col>
        </a-row>
        <div class="footer">
          <a-button type="primary" @click="onModify">修改</a-button>
          <a-button @click="goBack">返回</a-button>
        </div>
      </a-form>
    </section>
  </a-layout-content>
</template>

<script>
import { dtFormat, tsFormat } from "@/components/DateUtils";
import { buildHeaders, toChinesNum } from "@/components/utils";
import { baseURL, imageBaseUrl } from "@/services/HttpService";
import { loadArea } from "@/services/DealerService";
import { imgUrl } from "@/services/UploadService";
import { TreeSelect } from "ant-design-vue";
import {
  fetchAllServiceCenter,
  fetchArchivesDetail
} from "@/services/Archives";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const areaTreeFields = {
  children: "subAreas",
  title: "areaName",
  key: "areaCode",
  value: "areaCode"
};
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const showSalesColumns = [
  {
    title: "月份",
    dataIndex: "salesMonth",
    width: "50%"
  },
  {
    title: "销售数量",
    dataIndex: "salesVolume",

    width: "50%"
  }
];
export default {
  data() {
    return {
      SHOW_PARENT,
      areaTreeFields,
      toChinesNum,
      fileList: [],
      administerAreaCode: [],
      administerAreaName: [],
      reportFileList: {},
      imageBaseUrl,
      tblData: [],
      showSalesColumns,
      tsFormat,
      dtFormat,
      contractVO: {},
      checkCooperationUrl: "",
      checkCooperationName: "",
      baseURL,
      tableData: [],
      loading: false,
      procureOrderSn: this.$route.params.procureOrderSn,
      type: this.$route.params.type,
      action: this.$route.params.action,
      info: {},
      isCheck: false,
      headers: buildHeaders(),
      dateMonth: "",
      monthSkuList: [],
      editReceiveForm: this.$form.createForm(this),
      areaCode: [], // 选择的省市区code
      executorAreaCode: [], // 选择的省市区code
      areaTreeData: [], // 行政区数据
      administerAreaData: [], // 管辖行政区数据
      corporationId: this.$route.params.id,
      startTime: "",
      endTime: "",
      reportType: "",
      areaTreeNames: {
        label: "areaName",
        value: "areaCode",
        children: "subAreas"
      }, // 行政区树格式
      imgUrl,
      skuImages: [],
      testCode: "1201",
      previewVisible: false,
      previewImage: "",
      registeredAreaName: "",
      serviceCenterData: [],
      logisticsList: [],
      //地图插件
      mapPlugin: ["ToolBar"],
      campLocation: []
    };
  },
  mounted() {
    if (this.type === "渠道终端") {
      this.reportType = "endConsumerMarket";
    } else if (this.type === "货栈") {
      this.reportType = "warehouse";
    } else if (this.type === "运营中心") {
      this.reportType = "operationCenter";
    } else if (this.type === "服务中心") {
      this.reportType = "serviceCenter";
    } else if (this.type === "制造原厂") {
      this.reportType = "factory";
    }
    this.isCheck = this.action === "查看";
    this.onLoadArea();
    this.onLoadServiceCenter();
    if (this.corporationId && this.$route.query.distributorId) {
      this.onDetail();
    }
  },
  methods: {
    replace(value) {
      return (value = value.replace(/\s*/g, ""));
    },
    // 获取档案详情
    onDetail() {
      fetchArchivesDetail(
        this.reportType,
        this.$route.query.distributorId
      ).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          this.info = resp.data.data;
          let flag = [];
          if (data.sharedBusinessFlag) {
            flag.push("共享");
          }
          if (data.leasingBusinessFlag) {
            flag.push("租赁");
          }
          if (data.carLeaseBusinessFlag) {
            flag.push("分期购车");
          }
          if (data.sellBusinessFlag) {
            flag.push("批量买卖业务");
          }
          this.info.flag = flag;

          // 渠道终端 回显经营地址 经纬度
          if (data.longitude && data.latitude) {
            this.campLocation = [data.longitude, data.latitude];
          }
          if (this.info.registeredAreaName) {
            this.info.registeredAreaName = this.info.registeredAreaName.trim();
          }
          this.tblData = this.info.centerSalesVolumeVOList;
          if (
            this.info.tripartiteAgreementVOList &&
            this.info.tripartiteAgreementVOList.length > 0
          ) {
            this.contractVO = this.info.tripartiteAgreementVOList[0].contractVO;
          } else {
            this.contractVO = this.info.contractVO;
          }
          if (this.contractVO && this.contractVO.cooperationAgreementFile) {
            this.checkCooperationUrl = this.contractVO.cooperationAgreementFile.fileUrl;
            this.checkCooperationName = this.contractVO.cooperationAgreementFile.fileName;
          }
          if (
            this.info.jurisdictionAreaCodeMap &&
            this.info.jurisdictionAreaCodeMap.length > 0
          ) {
            this.info.jurisdictionAreaCodeMap.forEach(item => {
              this.administerAreaName.push(Object.values(item));
              this.administerAreaCode.push(Object.keys(item));
            });
            this.administerAreaCode = [].concat.apply(
              [],
              this.administerAreaCode
            );
            this.administerAreaName = [].concat
              .apply([], this.administerAreaName)
              .toString();
          } else {
            this.administerAreaName = "";
          }
          this.areaCode = [
            data.registeredProvinceCode,
            data.registeredCityCode,
            data.registeredCountyCode
          ];
          this.executorAreaCode = [
            data.executorProvinceCode,
            data.executorCityCode,
            data.executorCountyCode
          ];
          if (data.businessLicenseUrl) {
            const params = {
              uid: 1,
              fileUrl: data.businessLicenseUrl,
              name: data.businessLicenseUrl,
              status: "done",
              url: imageBaseUrl + data.businessLicenseUrl
            };
            this.skuImages.push(params);
          }
          if (data.cooperationAgreementFile) {
            const params = {
              uid: 1,
              fileUrl: data.cooperationAgreementFile.fileUrl,
              name: data.cooperationAgreementFile.fileName,
              status: "done",
              url: imageBaseUrl + data.cooperationAgreementFile.fileUrl
            };
            this.editReceiveForm.setFieldsValue({
              cooperationAgreement: params
            });
            this.fileList.push(params);
            const reportParams = {
              fileUrl: data.cooperationAgreementFile.fileUrl,
              fileName: data.cooperationAgreementFile.fileName
            };
            this.reportFileList = reportParams;
          }
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 获取服务中心列表
    onLoadServiceCenter() {
      fetchAllServiceCenter().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.serviceCenterData = resp.data.data;
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 修改档案
    onModify() {
      this.$router.replace({
        name: "ModifyArchives",
        params: {
          type: this.type,
          action: "修改",
          id: this.corporationId
        },
        query: {
          distributorId: this.$route.query.distributorId
        }
      });
    },
    // 调整时间
    onChangeTime(date, dateString) {
      if (dateString && dateString.length > 0) {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
      }
    },
    // 获取文件
    onChangeReport({ fileList }) {
      this.reportFileList = {};
      this.fileList = fileList;
      var nFileArr = new Array();
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        let keep = true;
        if (file.status !== "error") {
          if (file.response) {
            if (file.response.code === "FAILED") {
              keep = false;
              this.$message.error(file.response.errorMsg);
              return;
            }
          }
        }
        if (keep) {
          nFileArr.push(file);
        }
      }
      if (nFileArr.length > 0) {
        if (nFileArr[0].response && nFileArr[0].response.data) {
          const params = {
            uid: 1,
            fileUrl: nFileArr[0].response.data,
            name: nFileArr[0].name,
            status: "done",
            url: imageBaseUrl + nFileArr[0].response.data
          };
          const reportParams = {
            fileUrl: nFileArr[0].response.data,
            fileName: nFileArr[0].name
          };
          this.fileList.push(params);
          this.reportFileList = reportParams;
          this.fileList.splice(0, 1);
        }
      } else {
        this.fileList = nFileArr;
      }
    },
    // 拼接所选行政区信息
    onChangePer(value, selectOptions) {
      this.registeredAreaName = "";
      if (selectOptions && selectOptions.length > 0) {
        selectOptions.forEach(item => {
          this.registeredAreaName = `${this.registeredAreaName} ${item.areaName}`;
        });
        const arrValue = this.registeredAreaName.split(" ");
        this.areaValues = arrValue;
        if (arrValue[2] === arrValue[1]) {
          arrValue.splice(1, 1);
          this.registeredAreaName = arrValue.join(" ");
        }
      }
    },
    // 加载行政区数据
    onLoadArea() {
      this.areaTreeData = [];
      loadArea().then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaTreeData = resp.data.data;
          if (this.type === "运营中心") {
            this.administerAreaData = this.areaTreeData;
          } else {
            this.areaTreeData.forEach(item => {
              if (this.testCode.length === 2) {
                if (item.areaCode === this.testCode) {
                  this.administerAreaData.push(item);
                }
              }
              if (this.testCode.length === 4) {
                item.subAreas.forEach(items => {
                  if (items.areaCode === this.testCode) {
                    this.administerAreaData.push(items);
                  }
                });
              }
              if (this.testCode.length === 6) {
                item.subAreas.forEach(items => {
                  items.subAreas.forEach(itemss => {
                    if (itemss.areaCode === this.testCode) {
                      this.administerAreaData.push(itemss);
                    }
                  });
                });
              }
            });
          }
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 返回上一层
    goBack() {
      this.$router.go(-1);
    },
    // 在图片上传前判断大小
    beforeUploadFile(file) {
      const size = file.size;
      const fileName = JSON.stringify(file.name);
      return new Promise((resolve, reject) => {
        if (fileName.length > 100) {
          this.$message.error("图片名称的长度需在100个字以内");
          reject(file);
        } else {
          if (size > 20 * 1024 * 1024) {
            this.$message.error("图片大小超过20MB");
            reject(file);
          } else {
            resolve(file);
          }
        }
      });
    },
    async handlePreviewSkuImage(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeSkuImg({ fileList }) {
      this.skuImgs = fileList;
    },
    handleChangeSkuImage({ fileList }) {
      this.skuImages = fileList;
    }
  }
};
</script>

<style scoped>
.info-container {
  box-sizing: border-box;
  padding: 25px 30px 25px 30px;
  margin: 10px 20px 0 20px;
  background-color: white;
}

/deep/ .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: left;
  min-width: 137px !important;
}

.special__item /deep/ .ant-form-item-label > label {
  color: #101010;
  font-size: 14px;
  font-weight: bold;
}

.special__item /deep/ .ant-form-item-label > label::after {
  content: "";
  width: 4px;
}

.footer {
  display: flex;
  align-items: center;
}

.footer button {
  margin-right: 8px;
}
/deep/.ant-form-item .ant-form-item-control-wrapper {
  overflow: auto;
}
/deep/.ant-col-12 {
  width: 45%;
}

/deep/ .ant-row .ant-col-12:first-child {
  margin-right: 10%;
}

.link__url {
  width: calc(100% - 137px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
}

.preview__image {
  width: 140px;
  height: 140px;
  object-fit: cover;
}

.amap-wrapper {
  width: 450px;
  height: 350px;
}
</style>
